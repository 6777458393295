import React from "react"
import { ReactComponent as PempemLogo } from "../rsc/pempem_logo.svg";
import { colorPrimaryDark } from "../rsc/colors";
import Lottie from 'react-lottie'
import robotAnimation from '../rsc/lotties/robot.json'
import calculationAnimation from '../rsc/lotties/calculation.json'
import { t } from "i18next";

const robotOptions = {
    loop: true,
    autoplay: true,
    animationData: robotAnimation,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

const calculationOptions = {
    loop: true,
    autoplay: true,
    animationData: calculationAnimation,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

export function LoadingPricePage() {
    return (<div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: "#fff" }}>
        <PempemLogo fill={colorPrimaryDark} />
        <p style={{ fontSize: "36px", fontWeight: "600", color: colorPrimaryDark, maxWidth:"400px", textAlign:"center" }}>{t('fetch_price_loading_message')}</p>
        <div style={{ display: "flex", flexDirection: "column", margin:"30px 0" }}>
            <Lottie
                style={{alignSelf:"end", margin:0}}
                options={calculationOptions}
                width={70}
                height={64}
            />
            <Lottie
                options={robotOptions}
                width={204}
                height={220}
            />
        </div>
    </div>)
}