import React from "react";
import './ButtonPriceActionGold.scss'
import { ReactComponent as Arrow } from "../rsc/arrow.svg";

type Props = {
    text: string
    style: React.CSSProperties
    onClick?: () => void
}

export function ButtonPriceActionGold({ text, style, onClick }: Props) {
    return (
        <div style={{ ...style, aspectRatio: 435 / 38, display:"flex", cursor:"pointer" }} onClick={() => onClick?.()}>
            <div className={"gradient-background-gold"} style={{height:"100%", alignContent:"center", flex:"1", borderRadius:"10px 0 0 10px"}} >
                <p style={{ fontWeight: 700, textTransform: "uppercase", margin:0, textAlign:"center", padding:"0 10px" }}>{text}</p>
            </div>
            <div className="gradient-background-black" style={{height:"100%", alignContent:"center", padding:"0 10px 0 10px", display:"flex", alignItems:"center", borderRadius:"0 10px 10px 0"}}>
                <Arrow/>
            </div>
        </div>
    )
}