import { useEffect } from "react"
import { ErrorPopup } from "../component/ErrorPopup";

type Props = {
    page: string
    setNoPriceFound: any
    noPriceFound: boolean
}

export function PageFarmNoPrice({ page, setNoPriceFound, noPriceFound }: Props) {

    useEffect(() => {
        window.gtag("event", "page_view", { page_path: page });
        // eslint-disable-next-line
    }, [])
    return (
        <div style={{ zIndex: 100, alignItems: "center", position: "absolute", left: 0, right: 0, top: 0, bottom: 0, display: "flex", justifyContent: "center", backgroundColor: "#7C8282B3" }}>
            <ErrorPopup style={{ margin: "0 10px" }} onClose={() => { setNoPriceFound(false); window.history.back(); }} message="No Price was found. Please retry later." isVisible={noPriceFound} />
        </div>
    )
}