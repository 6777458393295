import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './i18n';
import './index.scss';
import { MainPage } from './pages/MainPage';
import { Helmet } from 'react-helmet';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// const TranslationComponent = () => {
//   const { i18n } = useTranslation();

//   useEffect(() => {
//     var lng = navigator.language
//     i18n.changeLanguage(lng);
//     // eslint-disable-next-line
//   }, [])

//   return <></>
// }

declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
}

type Props = {
  setScriptLoaded: any
}

const PageTracking = ({ setScriptLoaded }: Props) => {
  const isLoaded = useRef<boolean>(false)

  useEffect(() => {
    // Create a script element to load the gtag.js script
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_MEASUREMENT_ID}`;
    script.async = true;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    window.gtag = function gtag() {
      window.dataLayer.push(arguments);
    };
    window.gtag('js', new Date());
    window.gtag('config', process.env.REACT_APP_MEASUREMENT_ID);
    if (process.env.REACT_APP_ENV === "prod") {
      window.gtag('config', 'AW-385834636');
    }
    isLoaded.current = true
    setScriptLoaded(true)
    // eslint-disable-next-line
  }, [])

  return <></>
};

const Rooting = () => {
  const [scriptLoaded, setScriptLoaded] = useState<boolean>(false)

  return (
    <BrowserRouter >
      <PageTracking setScriptLoaded={setScriptLoaded} />

      {
        scriptLoaded &&
        <Routes>
          <Route path="/" element={<Navigate to={'/home'} />} />
          <Route path="/*" element={
            <div style={{ width: "100%", height: "100%" }}>
              <Helmet>
                <title>Pempem</title>
                <meta name="description" content="Nikmati Berniaga Sawit Yang Transparan, Jujur dan Adil Pempem: Beli Lebih Banyak Buah, Dengan Keberlanjutan" />
              </Helmet>
              <MainPage />
            </div>} />
        </Routes>
      }
    </BrowserRouter>
  )
}

root.render(
  <React.StrictMode>
    {/* <TranslationComponent /> */}
    <Rooting />
  </React.StrictMode>
);