import { Tooltip } from "@mui/material";
import { t } from "i18next";
import { EVENT_LINK_PEMPEM, isEnglish, sendTapEvent } from "../helper/helper";
import logoBg from "../rsc/background_header.png";
import { colorLight, colorPrimaryDark } from "../rsc/colors";
import { ReactComponent as PempemLogo } from "../rsc/pempem_logo.svg";
import { ReactComponent as Question } from "../rsc/question_tag.svg";


const { version } = require('../../package.json')

export function Header() {
    return (<div style={{ width: "100%", height: "60px", background: logoBg, position: "relative", zIndex: "10" }}>
        <img alt="background" style={{ width: "100%", height: "60px", objectFit: "cover" }} src={logoBg} />
        <Tooltip title={version} style={{ position: "absolute", right: "10px", top: "10px" }}>
            <PempemLogo fill={colorLight} style={{ position: "absolute", top: "50%", left: 10, transform: "translate(0%, -50%)" }} />
        </Tooltip>

        <div style={{ position: "absolute", top: 0, bottom: 0, right: '10px', display: "flex", alignItems: "center", cursor: "pointer" }}>
            <a href={isEnglish() ? "https://www.pempem.io/pempem-niaga" : "https://www.pempem.io/id/pempem-niaga"} style={{textDecoration:"none"}} onClick={() => sendTapEvent(EVENT_LINK_PEMPEM)}>
                <div style={{ padding: "8px 10px", display: "flex", gap: "5px", alignItems: "center", background: colorPrimaryDark, borderRadius: "20px", right: 0 }}>
                    <Question />
                    <p className="text-small" style={{ fontWeight: 700, color: "white" }}>{t('how_it_works')}</p>
                </div>
            </a>
        </div>
    </div>)
}