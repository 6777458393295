import { Button } from "@mui/material";
import { t } from "i18next";
import React, { forwardRef, useState } from "react";
import { ReactComponent as Extend } from "../rsc/arrow_extend.svg";
import { ReactComponent as Bubble } from "../rsc/bubble_emplty.svg";
import { colorGold, colorGreen, colorPrimary, colorPrimaryDark } from "../rsc/colors";
import { ReactComponent as Location } from "../rsc/location.svg";
import { ReactComponent as PlanteGauche } from "../rsc/plante_gauche.svg";
import { ReactComponent as PlanteDroite } from "../rsc/plante_droite.svg";
import './Annimation.scss';
import { STEP_TYPE } from "../pages/MainPage";

export const Instruction = forwardRef<HTMLDivElement, { type?: STEP_TYPE, askForLocation?: () => void }>((props, ref) => {
    const [extand, setExtand] = useState<boolean | undefined>(true)
    return (
        <div ref={ref} style={{
            position: "absolute",
            top: 60,
            zIndex: 9,
            left: 0,
            pointerEvents: "none",
            width: "100%", display: "flex",
            flexDirection: "column",
            alignItems: "center",
            '--translateY': 'calc(-100% + 40px)',
            animation: `${extand === undefined ? 'open 225ms forwards' : extand ? 'close 225ms forwards' : 'open 225ms forwards'}`
        } as React.CSSProperties}>
            {
                props.type === "home" ?
                    <div style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", display: "flex", pointerEvents: "auto", position: "relative", flexDirection: "column", background: "#fff", width: "100%", alignItems: "center", overflow: "hidden" }}>
                        <p className="text-big" style={{ color: colorPrimaryDark, fontWeight: 800, margin: "10px 0 0 0", padding: 0, zIndex:1 }}>{t('farm_instruction_title')}</p>
                        <div style={{ display: "flex", gap: "5px", textAlign: "center", zIndex:1 }}>
                            <p className="text-extra-big" style={{ color: colorPrimaryDark, fontWeight: 800, margin: 0, padding: 0 }}>{t('sell_fruit')} <span className="text-extra-big" style={{ color: colorGold, fontWeight: 800, margin: 0, padding: 0 }}>{t('at_mill')}</span></p>
                        </div>
                        <p className="text-medium-plus" style={{ padding: 0, color: "#000", fontWeight: 600, margin: "10px 0", zIndex: 1, textAlign: "center" }}>{t('farm_instruction_0')}</p>
                        <div style={{ position: "absolute", width: "100%", bottom: -40, left: 0, right: 0, display: "flex", justifyContent: "space-between" }}>
                            <div>
                                <PlanteGauche style={{ width: "280px", marginLeft: "-210px" }} />
                            </div>
                            <div style={{ display: "flex", justifyContent: "end" }}>
                                <PlanteDroite style={{ width: "280px", marginRight: "-210px" }} />
                            </div>
                        </div>
                    </div>
                    : props.type === "pickup" ?
                        <div style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", background: "#fff", pointerEvents: "auto", width: "100%" }}>
                            <div style={{ display: "flex", flexDirection: "column", margin: "10px 10px 20px 10px", gap: "10px", textAlign: "center", alignItems: "center" }}>
                                <p className="text-big" style={{ color: colorPrimaryDark, fontWeight: 800, margin: 0, padding: 0, textAlign: "center" }}>{t('price_with_transport')}</p>
                                <div style={{ display: "flex", gap: "5px", justifyContent: "center" }}>
                                    <p className="text-extra-big" style={{ color: colorPrimaryDark, fontWeight: 800, margin: 0, padding: 0 }}>{t('sell_fruit')} <span className="text-extra-big" style={{ color: colorGreen, fontWeight: 800, margin: 0, padding: 0 }}>{t('at_farm')}</span></p>
                                </div>
                                <p style={{ color: colorPrimaryDark, fontSize: "16px", fontWeight: 600, margin: 0, padding: 0 }}>{t('pickup_instruction_0')}</p>
                                <div style={{ display: "flex", gap: "15px" }}>
                                    <p style={{ color: colorPrimaryDark, fontSize: "16px", fontWeight: 600, margin: 0, padding: 0 }}>{t('pickup_instruction_1')}</p>
                                    <div style={{ position: "relative" }}>
                                        <p style={{ color: colorPrimary, fontSize: "16px", fontWeight: 600 }}>pickup</p>
                                        <Bubble width={"78px"} height={"35px"} style={{ position: "absolute", top: "-1px", left: "-13px" }} />
                                    </div>
                                    <p style={{ color: colorPrimaryDark, fontSize: "16px", fontWeight: 600, margin: 0, padding: 0 }}>{t('pickup_instruction_2')}</p>
                                </div>
                            </div>
                        </div>
                        // : props.type === "price_farm" ?
                        //     <div style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", background: "#fff", pointerEvents: "auto", width: "100%" }}>
                        //         <div style={{ display: "flex", flexDirection: "column", margin: "0 10px 20px 10px", gap: "10px" }}>
                        //             <p style={{ color: colorPrimaryDark, fontWeight: 600, fontSize: "36px" }}>{t('fruit_at_farm_title')}</p>
                        //             <p style={{ color: colorPrimaryDark, fontSize: "16px", fontWeight: 600 }}>{t('fruit_at_farm_subtitle')}</p>
                        //         </div>
                        //     </div>
                            : <></>

            }
            <div style={{ display: "flex", pointerEvents: "none", width: "100%", placeContent: "space-between" }}>
                <Button style={{ margin: "5px 0 0 5px", pointerEvents: "auto", background: colorPrimaryDark, padding: 7, minWidth: 0 }} onClick={() => props.askForLocation?.()}>
                    <Location fill="#fff" width={"25px"} height={"25px"} />
                </Button>
                <Button style={{ margin: "5px 5px 0 0", pointerEvents: "auto", background: colorPrimaryDark, padding: 7, minWidth: 0 }} onClick={() => setExtand(extand ? !extand : true)}>
                    <Extend width={"25px"} height={"25px"} />
                </Button>
            </div>
        </div>)
})