import { useEffect } from "react"
import { ContactToSellMyFruit } from "./ContactToSellMyFruit";

type Props = {
    page: string
    millPriceAccepted: number | undefined
}

export function PagePriceMillAccepted({ page, millPriceAccepted }: Props) {

    useEffect(() => {
        window.gtag("event", "page_view", { page_path: page });
        // eslint-disable-next-line
    }, [])
    return (
        <div style={{ zIndex: 100, alignItems: "center", position: "absolute", left: 0, right: 0, top: 0, bottom: 0, display: "flex", justifyContent: "center", backgroundColor: "#7C8282B3" }}>
            <ContactToSellMyFruit style={{ margin: "0 10px" }} price={millPriceAccepted!} type={"mill"} />
        </div>
    )
}