import { colorPrimary, colorPrimaryDark, colorYellowLight } from "../rsc/colors";
import { ReactComponent as Open } from "../rsc/open.svg";
import { ReactComponent as Phone } from "../rsc/phone.svg";
import GooglePlayStoreg from "../rsc/playstore.png";
import { ReactComponent as Check } from "../rsc/check.svg";
import { ReactComponent as Mobile } from "../rsc/mobile.svg";
import "./ErrorPopup.scss";
import { t } from "i18next";
import { EVENT_LINK_APP, EVENT_LINK_PHONE, EVENT_LINK_PLAYSTORE, EVENT_LINK_WHATSAPP, REDIRECT_URL, sendTapEvent } from "../helper/helper";
import Whatsapp from "../rsc/whatsapp.png";

type Props = {
    message: string
    style?: React.CSSProperties
    isVisible: boolean
    onClose: () => void
}

export function ErrorPopup({ message, style, isVisible, onClose }: Props) {
    return (<div
        style={{
            ...style,
            display: "flex", flexDirection: "column", background: "#fff", padding: "20px", gap: "10px", borderRadius: "8px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
        }}>
        <div style={{ display: "flex", alignItems: "center" }}>
            <p className="text-extra-big" style={{ color: colorPrimaryDark, fontWeight: "1000" }}>{t('call_pempem')}</p>
        </div>
        <div style={{ background: colorYellowLight, padding: "10px", display: "flex", justifyContent: "space-between" }}>
            <p style={{ margin: 0, fontWeight: "600", fontSize: "14px", alignSelf: "end" }}>{t('message_no_price')}</p>
        </div>

        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", gap: "10px", marginTop: "10px" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <Check />
                <p className="text-medium" style={{ padding: 0, margin: 0, color: "#079AA8", fontWeight: "600" }}>{t('transport_included')}</p>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "5px", fontWeight: "600" }}>
                <Check />
                <p className="text-medium" style={{ padding: 0, margin: 0, color: "#079AA8", fontWeight: "600" }}>{t('no_grading_at_farm')}</p>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <Check />
                <p className="text-medium" style={{ padding: 0, margin: 0, color: "#079AA8", fontWeight: "600" }}>{t('digital_scale')}</p>
            </div>
        </div>
        <p className="text-very-big" style={{ color: colorPrimaryDark, fontWeight: 800 }}>{t('call_pempem_message')}</p>

        <a href="https://wa.me/6285277768665" style={{ textDecoration: "none" }} onClick={() => sendTapEvent(EVENT_LINK_WHATSAPP)}>
            <div
                style={{
                    background: "#25D366",
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: "8px",
                    height:"60px",
                    gap: "10px", alignItems: "center", cursor: "pointer"
                }} >
                <img alt="" src={Whatsapp} height={"60px"}></img>
            </div>
        </a>
        <a href="tel:+627619000510" style={{ textDecoration: "none" }} onClick={() => sendTapEvent(EVENT_LINK_PHONE)}>
            <div className="gradient-background-white-blue"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    border: `solid 2px ${colorPrimary}`,
                    borderRadius: "8px",
                    height:"56px",
                    gap: "10px", alignItems: "center", cursor: "pointer"
                }} >
                <Phone />
                <p className="text-very-big" style={{ fontWeight: "600", color: colorPrimaryDark, textDecoration: "none" }}>{t('call_number')}</p>
            </div>
        </a>

        <p className="text-very-big" style={{ color: colorPrimaryDark, fontWeight: "600" }}>{t('download_pempem_app_instruction')}</p>

        <a href={REDIRECT_URL} onClick={() => sendTapEvent(EVENT_LINK_PLAYSTORE)}>
            <div
                style={{
                    display: "flex", justifyContent: "center",
                    background: 'black',
                    cursor: "pointer",
                    height:"60px",
                    borderRadius: "8px",
                    alignItems:"center"
                }} >
                <img alt="background" style={{ height: "40px" }} src={GooglePlayStoreg} />
            </div>
        </a>
        <a href={REDIRECT_URL} style={{ textDecoration: "none" }} onClick={() => sendTapEvent(EVENT_LINK_APP)}>
            <div className="gradient-background-white-blue"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    border: `solid 2px ${colorPrimary}`,
                    borderRadius: "8px",
                    height: "56px",
                    gap: "10px", alignItems: "center", cursor: "pointer"
                }} >
                <Mobile />
                <p className="text-very-big" style={{ fontWeight: "600", color: colorPrimaryDark, textDecoration: "none" }}>{t('launch_app')}</p>
                <Open />
            </div>
        </a>
    </div>)
}