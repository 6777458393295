import mapboxgl, { Map } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { defaultLat, defaultLng } from '../helper/helper';
import "./Map.css";

export type MapPos = {
    lat: number
    lng: number
}

interface MapBoxProps {
    pos?: any
    initZoom?: number
    style: CSSProperties,
    stateToReload?: any,
    showExpand?: boolean,
    onLoaded: (map: Map) => void,
    onEditPosition?: (map: Map) => void,
    onSavePostion?: (map: Map) => void,
    onCancelEditPosition?: (map: Map) => void,
}

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default; /* eslint import/no-webpack-loader-syntax: off */

function MapBox({ pos = {lng: defaultLng, lat: defaultLat},
    initZoom = 13,
    stateToReload = null,
    onLoaded = () => { },
    style
}: MapBoxProps) {

    var mapContainer = useRef<HTMLDivElement | null>(null);
    var map = useRef<Map>();
    const [stateLng] = useState(pos.lng);
    const [stateLat] = useState(pos.lat);
    const [zoom] = useState(initZoom);

    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN ?? "";

    useEffect(() => {
        if (map.current?.loaded() === true) {
            onLoaded(map.current)
            map.current.flyTo({
                center: [pos.lng, pos.lat],
                essential: true // this animation is considered essential with respect to prefers-reduced-motion
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateToReload])

    useEffect(() => {
        if (map.current) return; // initialize map only once
        // eslint-disable-next-line react-hooks/exhaustive-deps
        map.current = new mapboxgl.Map({
            container: 'map',
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [stateLng, stateLat],
            zoom: zoom
        });
        // map.current.addControl(
        //     new mapboxgl.GeolocateControl({
        //         positionOptions: {
        //             enableHighAccuracy: true
        //         },
        //         // When active the map will receive updates to the device's location as it changes.
        //         trackUserLocation: true,
        //         // Draw an arrow next to the location dot to indicate which direction the device is heading.
        //         showUserHeading: true
        //     })
        // );
        map.current.on("load", () => {
            onLoaded(map.current!!)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        map.current?.flyTo({
            center: [stateLng, stateLat],
            essential: true // this animation is considered essential with respect to prefers-reduced-motion
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <div style={style}>
            <div id="map" style={{ width: "100%", height: "100%" }} ref={mapContainer}></div>
        </div>
    )
}

export default MapBox
