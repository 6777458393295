export class PriceModel {

    id: number
    pricePerUnit?: number
    millId?: number
    millName?: string
    subdistrict?: string
    village?: string
    coordinates?: number[]

    constructor(id: number, price?: number, millName?: string, millId?: number, subdistrict?: string, village?: string, coordinates?: number[]) {
        this.id = id
        this.pricePerUnit = price
        this.millId = millId
        this.millName = millName
        this.subdistrict = subdistrict
        this.village = village
        this.coordinates = coordinates
    }

    location() {
        return `${this.subdistrict}, ${this.village}`
    }
}