import { useEffect } from "react"
import { CardPriceAtFarm } from "../component/CardPriceAtFarm";

type Props = {
    page: string
    setPickUp: () => void
}

export function PagePriceAtMill({ page, setPickUp }: Props) {

    useEffect(() => {
        window.gtag("event", "page_view", { page_path: page });
        // eslint-disable-next-line
    }, [])
    return (
        <div style={{ position: 'absolute', zIndex: 9, bottom: 0, left: 0, right: 0, display: "flex", justifyContent: "center", pointerEvents: "none" }}>
            <CardPriceAtFarm style={{ maxWidth: "500px", flex: "1", pointerEvents: "auto" }} onClick={() => setPickUp()} />
        </div>
    )
}