import { Snackbar } from "@mui/material";
import axios from "axios";
import mapboxgl, { Map, Marker } from "mapbox-gl";
import { useEffect, useRef, useState } from "react";
import { Route, Routes, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import '../component/Annimation.scss';
import { Header } from "../component/Header";
import { Instruction } from "../component/Instruction";
import { Loader } from "../component/Loader";
import { defaultLat, defaultLng, EVENT_MAP_MOVED, sendTapEvent } from "../helper/helper";
import { initPriceLayer, priceVisibility, updateSource } from "../helper/mapDataFeed";
import { PriceModel } from "../model/PriceModel";
import PickupMarker from "../rsc/pickup.svg";
import { LoadingPricePage } from "./LoadingPricePage";
import MapBox from "./MapBox";
import { PageFarmNoPrice } from "./PageFarmNoPrice";
import { PagePickUp } from "./PagePickUp";
import { PagePriceAtMill } from "./PagePriceAtMill";
import { PagePriceFarm } from "./PagePriceFarm";
import { PagePriceMillAccepted } from "./PagePriceMillAccepted";
import { PagePriceMillSelected } from "./PagePriceMillSelected";

export type STEP_TYPE =
    | "home"
    | "price_mill_selected"
    | "price_mill_accepted"
    | "price_farm_no_price"
    | "price_farm_received"
    | "pickup";

export type NavigationState = {
    step: STEP_TYPE
    nafPrice?: number
    timestamp?: number
}

export type Param = {
    key: string
    value: any
}

export function MainPage() {

    const [param] = useSearchParams()

    const initParam = useRef<any>(param)

    const location = useLocation();

    const didDraggedMap = useRef<boolean>(false)


    const instructionRef = useRef<HTMLDivElement>(null)
    const [priceSelected, setPriceSelected] = useState<PriceModel | undefined>(undefined)
    const [jsonPrices, setJsonPrices] = useState<any>(undefined)
    const jsonPricesRef = useRef<any>(undefined)
    const mapRef = useRef<Map | undefined>(undefined)
    const [instructionType, setInstructionType] = useState<STEP_TYPE>("home")

    const [nafPrice, setNafPrice] = useState<number | undefined>(undefined)
    const [millPriceAccepted, setMillPriceAccepted] = useState<number | undefined>(undefined)

    const userPos = useRef<any | undefined>(undefined)

    // eslint-disable-next-line
    const pickupPos = useRef<any | undefined>(undefined)
    const cameraPos = useRef<any | undefined>({ lng: defaultLng, lat: defaultLat })

    const markerRef = useRef<Marker | undefined>(undefined)

    const [loadingPrice, setLoadingPrice] = useState(false)

    const [error, setError] = useState<string | undefined>(undefined)

    const [askPosLoader, setAskPosLoader] = useState<boolean>(false)

    const [noPriceFound, setNoPriceFound] = useState<boolean>(false)

    const navigate = useNavigate();

    function fetchNafPrice() {
        setLoadingPrice(true)
        axios.get(`${process.env.REACT_APP_BASE_URL_PUBLIC}/mapTilingService/bidByPos/${pickupPos.current.lng}/${pickupPos.current.lat}`, {
            withCredentials: false,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then((data: any) => {
            const price = data.data.price
            if (price) {
                onFarmPriceReceived(price)
            } else {
                const customState: NavigationState = { step: "price_farm_no_price" }
                nextStep('price_farm_no_price', customState)
                setNoPriceFound(true)
            }
        }).catch(e => {
            if (e?.message) {
                setError(e?.message)
            } else {
                setError('An error occured')
            }
        })
            .finally(() => {
                setLoadingPrice(false)
            })
    }

    function fetchAllPrices() {
        axios.get(`${process.env.REACT_APP_BASE_URL_PUBLIC}/mapTilingService/priceMVTSource/0/0/0.pbf?cs=facebook`, {
            withCredentials: false,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then((data: any) => {
            jsonPricesRef.current = data.data[0].geoJson
            setJsonPrices(data.data[0].geoJson)
        }).catch(e => {
            if (e?.message) {
                setError(e?.message)
            } else {
                setError('An error occured')
            }
        })
            .finally(() => {
                // setLoading(false)
            })
    }

    function priceAsParam(price: PriceModel): Param[] {
        return [
            { key: "id", value: price.id },
            { key: "price", value: price.pricePerUnit },
            { key: "mill_name", value: price.millName },
            { key: "subdistrict", value: price.subdistrict },
            { key: "village", value: price.village },
        ]
    }

    function handleVisibility() {
        const path = location.pathname.split('/').pop() as STEP_TYPE
        updateInstruction(path)
        if (path !== "pickup") {
            if (path === 'home') {
                setPriceSelected(undefined)
            }
            hidePickup()
            if (mapRef.current) {
                priceVisibility(mapRef.current, true)
            }
        } else {
            if (markerRef.current) {
                showPickup()
            } else {
                setPickUp()
            }
            if (mapRef.current) {
                priceVisibility(mapRef.current, false)
            }
        }
    }

    function mapLoaded(map: Map) {
        mapRef.current = map;
        initPriceLayer(mapRef.current, jsonPrices, (p: any, g: any) => {
            const price = new PriceModel(p.id, p.price, p.organisation_name, p.organisation_id, p.location_subdistrict, p.location_village, g.coordinates)
            const exParams = priceAsParam(price)
            setPriceSelected(price)
            const customState: NavigationState = { step: "price_mill_selected", timestamp: Date.now() };
            nextStep('price_mill_selected', customState, exParams)
        }, () => {
            fetchAllPrices()
            handleVisibility()
        })
        mapRef.current.on('dragend', () => {
            if (!didDraggedMap.current) {
                sendTapEvent(EVENT_MAP_MOVED)
                didDraggedMap.current = true
            }
            const center = map.getCenter(); // returns a LngLat object
            cameraPos.current = { lng: center.lng, lat: center.lat }
        });
    }

    useEffect(() => {
        if (jsonPrices) {
            updateSource(mapRef.current!, jsonPrices, priceSelected?.id)
        }
        if (priceSelected) {
            flyTo([priceSelected.coordinates!.at(0)!, priceSelected.coordinates!.at(1)!])
        }
        // eslint-disable-next-line
    }, [jsonPrices, priceSelected])

    function setPickUp(byPassUserPos: boolean = false) {
        if ((userPos.current || byPassUserPos) && mapRef.current) {
            priceVisibility(mapRef.current, false)
            addPickUpMarker()

            const customState: NavigationState = { step: "pickup", timestamp: Date.now() };
            nextStep('pickup', customState)
        } else {
            askForPosition(() => {
                setPickUp()
                flyToUserPos()
            }, () => {
                setPickUp(true)
            })
        }
    }

    const flyTo = (coord: number[]) => {
        mapRef.current?.flyTo({
            center: [coord[0], coord[1]],
            essential: true // this animation is considered essential with respect to prefers-reduced-motion
        });
    }

    function flyToUserPos() {
        const ppos = getPickupPos()
        flyTo([ppos.lng, ppos.lat])
    }

    function askForPosition(onSuccess?: () => void, onError?: () => void) {
        if (!navigator.geolocation) {
            setError('Geolocation is not supported by this browser.');
        } else if (mapRef.current) {
            setAskPosLoader(true)
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setAskPosLoader(false)
                    const { latitude, longitude } = position.coords;
                    userPos.current = { latitude, longitude }
                    const pickupPos = getPickupPos()
                    const control = new mapboxgl.GeolocateControl({
                        positionOptions: { enableHighAccuracy: true },
                        trackUserLocation: true,
                        showUserLocation: true,
                        showAccuracyCircle: false,
                        fitBoundsOptions: { center: [pickupPos.lng, pickupPos.lat], zoom: mapRef.current?.getZoom() }
                    })
                    control.on('error', (e: any) => {
                        console.log('controle failure', error)
                    })
                    control.on('geolocate', (e: any) => {
                        console.log('controle success', error)
                    })
                    if (mapRef.current?.hasControl(control) === false) {
                        mapRef.current?.addControl(control);
                        setTimeout(() => {
                            control.trigger()
                        }, 1000)
                    }
                    onSuccess?.()
                },
                (err) => {
                    setError(err.message);
                    setAskPosLoader(false)
                    onError?.()
                }
                , {
                    timeout: 7000
                });
        }
    }

    useEffect(() => {
        if (!nafPrice) {
            setLoadingPrice(false)
        }
        // eslint-disable-next-line
    }, [nafPrice])

    function addPickUpMarker() {
        if (markerRef.current) {
            showPickup()
        } else {
            const customMarker = document.createElement('div');
            const markerImg = document.createElement('img');
            markerImg.src = PickupMarker;
            markerImg.style.width = "170px"
            markerImg.style.width = "85px"

            // Append the image to the custom marker
            customMarker.appendChild(markerImg);
            customMarker.style.zIndex = "8"
            pickupPos.current = getPickupPos()
            const marker = new mapboxgl.Marker({
                element: customMarker,
                draggable: true,
                anchor: 'bottom'
            })
                .setLngLat([pickupPos.current.lng, pickupPos.current.lat])
                .addTo(mapRef.current!);

            marker.on('dragend', () => {
                const lngLat = marker.getLngLat();
                pickupPos.current = { lng: lngLat.lng, lat: lngLat.lat }
            });
            markerRef.current = marker
        }
    }

    function getPickupPos() {
        return pickupPos.current ?? { lng: userPos?.current?.longitude ?? defaultLng, lat: userPos?.current?.latitude ?? defaultLat }
    }

    function nextStep(step: STEP_TYPE, state?: NavigationState, extraParam?: Param[]) {
        var newParam: string = ''
        initParam.current.forEach((value: string, key: string) => {
            if (newParam.length > 0) {
                newParam += '&'
            }
            newParam += `${key}=${value}`
        })
        extraParam?.forEach((p: Param) => {
            if (newParam.length > 0) {
                newParam += '&'
            }
            newParam += `${p.key}=${p.value}`
        })
        if (newParam) {
            newParam = '?' + newParam
        }
        updateInstruction(step)
        if (newParam.length > 0) {
            navigate(`/${step}${newParam}`, { state: state });
        } else {
            navigate(`/${step}`, { state: state });
        }
    }

    function showPickup() {
        if (markerRef.current) {
            pickupPos.current = getPickupPos()
            markerRef.current.setLngLat([pickupPos.current.lng, pickupPos.current.lat])
            markerRef.current!.getElement().style.display = "block"
        }
    }

    function hidePickup() {
        if (markerRef.current) {
            markerRef.current!.getElement().style.display = "none"
        }
    }

    useEffect(() => {
        handleVisibility()
        // eslint-disable-next-line
    }, [location]);


    function updateInstruction(step: STEP_TYPE) {
        if (step === 'pickup' || step === 'price_farm_received') {
            setInstructionType('pickup')
        } else {
            setInstructionType('home')
        }
    }

    function onMillPriceAccepted(price?: number) {
        const customState: NavigationState = { step: "price_mill_accepted" }
        nextStep('price_mill_accepted', customState)
        setMillPriceAccepted(price)
    }

    function onFarmPriceReceived(price: number) {
        const customState: NavigationState = { step: "price_farm_received" }
        nextStep('price_farm_received', customState)
        setNafPrice(price)
        setLoadingPrice(false)
    }

    return (<div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
        <Header />
        <Instruction ref={instructionRef} type={instructionType} askForLocation={() => askForPosition()} />
        <div style={{ width: "100%", flex: 1, position: "relative" }}>
            <MapBox initZoom={9} style={{ width: "100%", height: "100%" }} onLoaded={(map: Map) => { mapLoaded(map) }} />
        </div>
        <Routes>
            <Route path="home" element={
                <PagePriceAtMill page="mill_prices_map" setPickUp={setPickUp} />
            } />
            <Route path="price_mill_selected" element={
                <PagePriceMillSelected setPickUp={setPickUp} page="price_mill_selected" onMillPriceAccepted={onMillPriceAccepted} priceSelected={priceSelected} setPriceSelected={(price: any) => {
                    if (!price) {
                        nextStep('home')
                    }
                    setPriceSelected(price)
                }} />
            } />
            <Route path="price_mill_accepted" element={
                <PagePriceMillAccepted page="price_mill_accepted" millPriceAccepted={millPriceAccepted} />
            } />
            <Route path="price_farm_no_price" element={
                <PageFarmNoPrice page="price_farm_no_price" setNoPriceFound={setNoPriceFound} noPriceFound={noPriceFound} />
            } />
            <Route path="price_farm_received" element={
                <PagePriceFarm page="price_farm_received" nafPrice={nafPrice} />
            } />
            <Route path="pickup" element={
                <PagePickUp page="pickup" fetchNafPrice={fetchNafPrice} />
            } />
        </Routes>
        {
            loadingPrice &&
            <div style={{ position: "absolute", top: '60px', bottom: 0, left: 0, right: 0, zIndex: "9", backgroundColor: "#fff", display: "flex", justifyContent: "center" }}>
                <LoadingPricePage />
            </div>
        }
        <Loader isLoading={askPosLoader} />
        {
            error ?
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center"
                    }}
                    open={error !== undefined}
                    autoHideDuration={6000}
                    onClose={() => { setError(undefined) }}
                    message={error}
                    sx={{
                        '& .MuiSnackbarContent-root': {
                            backgroundColor: "#f00",
                            fontWeight: "600"
                        }
                    }}
                /> : <></>
        }
    </div >)
}