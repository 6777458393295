import i18n from "../i18n"

export const defaultLng = 101.450761
export const defaultLat = 0.526719

export const EVENT_LINK_PEMPEM = "welcome_pempem_io"
export const EVENT_LINK_PLAYSTORE = "welcome_playstore"
export const EVENT_LINK_APP = "welcome_pempem_app"
export const EVENT_LINK_PHONE = "welcome_phone"
export const EVENT_LINK_WHATSAPP = "welcome_whatsapp"
export const EVENT_MAP_MOVED = "welcome_map_moved"

export const REDIRECT_URL = "https://poapp.pempem.org/applinks?destination=app&path=price_map&origin=welcome"

export function toIndoFormat(value: number, maxDigit: number = 0): string {
    return Intl.NumberFormat('id-ID', {
        minimumFractionDigits: 0,
        maximumFractionDigits: maxDigit
    }).format(value)
}

export function sendTapEvent(value: string) {
    window.gtag("event", "pp_tap_link", { pp_link: value });
}

export function isEnglish() {
    return i18n.language.includes('en')
}