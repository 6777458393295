import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { CardPriceAtFarm } from "../component/CardPriceAtFarm";
import { CardPriceAtMill } from "../component/CardPriceAtMill";
import { PriceModel } from "../model/PriceModel";

type Props = {
    page: string
    onMillPriceAccepted: any
    priceSelected: PriceModel | undefined
    setPriceSelected: any
    setPickUp: () => void
}

export function PagePriceMillSelected({ page, onMillPriceAccepted, setPriceSelected, setPickUp }: Props) {

    const [param] = useSearchParams()
    var pid = param.get("id")
    var pprice = param.get("price")
    var pmill_name = param.get("mill_name")
    var psub_district = param.get("subdistrict")
    var pvillage = param.get("village")

    console.log(param.get("id"))

    const price = new PriceModel(pid ? parseInt(pid) : 0, pprice ? parseInt(pprice) : 0, pmill_name ?? undefined, undefined, psub_district ?? undefined, pvillage ?? undefined)

    useEffect(() => {
        window.gtag("event", "page_view", { page_path: page });
        // eslint-disable-next-line
    }, [])

    return (
        <div style={{ position: 'absolute', zIndex: 8, bottom: 0, left: 0, right: 0, display: "flex", justifyContent: "center", pointerEvents: "none" }}>
            <div id="toto" style={{ maxWidth: "500px", flex: 1 }}>
                <CardPriceAtMill style={{ flex: "1", pointerEvents: "auto", margin: "0 10px" }}
                    onClick={() => onMillPriceAccepted(price.pricePerUnit)}
                    price={price}
                    close={() => setPriceSelected(undefined)} />
                <CardPriceAtFarm style={{ flex: "1", pointerEvents: "auto", marginTop: "10px" }} onClick={() => setPickUp()} />
            </div>
        </div>
    )
}