import { GeoJSONSource, Map } from "mapbox-gl";
import Bubble from "../rsc/bubble.png";
import BubbleGold from "../rsc/bubble_gold.png";
import { colorPrimaryDark } from "../rsc/colors";

function overlayImagePrice(map: Map): Promise<string[]> {
    var image1 = new Image();
    image1.src = Bubble;
    const bubble: Promise<string> = new Promise((resolver) => {
        image1.onload = function () {
            map.addImage('bubble', image1, { pixelRatio: 1.5 })
            resolver(image1.src)
        }
    })
    var image2 = new Image();
    image2.src = BubbleGold;
    const bubbleGold = new Promise<string>((resolver) => {
        image2.onload = function () {
            map.addImage('bubble-gold', image2, { pixelRatio: 1.5 })
            resolver(image2.src)
        }
    })
    return Promise.all([bubble, bubbleGold])
}

export function updateSource(map: Map, prices: any, priceSelected?: number) {
    var json = getPriceSource(prices, priceSelected);
    (map.getSource("prices") as GeoJSONSource).setData(json);
}

export function priceVisibility(map: Map, isVisible: boolean) {
    map.setLayoutProperty('prices', 'visibility', isVisible ? 'visible' : 'none');
}

function getPriceSource(json: any, priceSelected?: number): any {
    json?.features?.forEach((price: any, i: number) => {
        price.properties = {
            ...price.properties, price: price.properties.program_price_per_unit ?? price.properties.price_per_unit,
            icon: price.properties.id === priceSelected ? "bubble-gold" : "bubble",
            textColor: price.properties.id === priceSelected ? '#000' : colorPrimaryDark,
            sortOrder: i
        }
    })
    return json
}

export function initPriceLayer(map: Map, json: any, onCLick: (featureId: any, geometry: any) => void, onLoaded: () => void) {
    overlayImagePrice(map)
        .then((data: string[]) => {
            const source = json ? getPriceSource(json) : {
                'type': 'FeatureCollection',
                'features': []
            }
            map.addSource('prices', {
                "type": 'geojson',
                'data': source
            })
            map.addLayer({
                'id': 'prices',
                'type': 'symbol',
                'source': 'prices',
                'layout': {
                    "icon-allow-overlap": true,
                    "icon-ignore-placement": true,
                    "text-allow-overlap": true,
                    "text-ignore-placement": true,
                    'icon-image': ['get', 'icon'],
                    'icon-anchor': 'bottom',
                    'text-field': ['get', 'price'],
                    'text-offset': [0.6, -0.9],
                    'text-anchor': 'bottom',
                    'text-font': ['Open Sans Bold'],
                    "symbol-sort-key": ["get", "sortOrder"],
                    'text-size': 16
                },
                'paint': {
                    'text-color': ['get', 'textColor'],
                }
            })
            map.on('click', ['prices'], (ev) => {
                onCLick(ev.features?.at(0)?.properties, ev.features?.at(0)?.geometry)
            })
            onLoaded()
        })
}