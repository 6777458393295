import { useEffect } from "react";
import { CardPriceAtFarmFetch } from "../component/CardPriceAtFarmFetch";

type Props = {
    page: string
    fetchNafPrice: () => void
}

export function PagePickUp({ page, fetchNafPrice }: Props) {

    useEffect(() => {
        window.gtag("event", "page_view", { page_path: page });
        // eslint-disable-next-line
    }, [])

    return (
        <div style={{ position: 'absolute', zIndex: 9, bottom: 10, left: 10, right: 10, display: "flex", justifyContent: "center", pointerEvents: "none" }}>
            <CardPriceAtFarmFetch style={{ maxWidth: "500px", flex: "1", pointerEvents: "auto" }} onClick={() => fetchNafPrice()} />
        </div>
    )
}