import { t } from "i18next";
import React from "react";
import { toIndoFormat } from "../helper/helper";
import { PriceModel } from "../model/PriceModel";
import { ReactComponent as Close } from "../rsc/close.svg";
import { colorGold, colorPrimaryDark, colorYellowLight, colorYellowMedium } from "../rsc/colors";
import { ReactComponent as Mill } from "../rsc/mill.svg";
import { ReactComponent as PempemLogo } from "../rsc/pempem_logo.svg";
import { ButtonPriceActionGold } from "./ButtonPriceActionGold";

type Props = {
    style?: React.CSSProperties
    price?: PriceModel
    close?: () => void
    onClick?: () => void
}

export function CardPriceAtMill({style, price, close, onClick}: Props) {
    return (<div style={{ ...style, display: "flex", flexDirection: "column", background:colorYellowLight, border:`solid 3px ${colorGold}`, position:"relative" }}>
        <div style={{ display: "flex", justifyContent:"center",alignItems:"center", gap:"5px",borderRadius:"5px", backgroundColor:colorYellowMedium, margin:"10px", padding:"5px 0" }}>
            <p style={{textTransform:"uppercase", color:"#075255", fontWeight:600, fontSize:"12px"}}>{t('price_at_mill_by')}</p>
            <PempemLogo style={{height:"24px", width:"80px"}} fill={colorPrimaryDark} />
        </div>

        <div style={{ display: "flex", alignItems:"center", margin:"0 10px 0 10px" }}>
            <Mill />
            <div style={{ display: "flex", flexDirection: "column", flex: "1", gap:10, margin:"0 0 0 10px" }}>
                <p style={{margin:0, padding:0, fontWeight:"600", fontSize:"15px"}}>{price?.millName}</p>
                <p style={{margin:0, padding:0, fontWeight:"300", fontSize:"13px"}}>{price?.location()}</p>
            </div>
            <div style={{display:"flex", alignItems:"center", gap:"5px"}}>
                <p style={{margin:0, fontWeight:"600", fontSize:"14px", alignSelf:"end"}}>Rp/KG</p>
                <p style={{margin:0, fontWeight:"600", fontSize:"34px", lineHeight:"30px"}}>{toIndoFormat(price?.pricePerUnit ?? 0)}</p>
            </div>
        </div>
        <ButtonPriceActionGold onClick={() => onClick?.()} style={{margin:"10px", maxHeight:"45px", maxWidth:"500px"}} text={t('sell_to_pempem_at_mill')}/>
        <div onClick={() => close?.()} style={{cursor:"pointer", position:"absolute", top:"-21px", right:"-13px", height:"42px", width:"42px", backgroundColor:colorGold, borderRadius:"21px", display:"flex", alignItems:"center", justifyContent:"center"}}>
            <Close/>
        </div>
    </div>)
}