import { t } from "i18next";
import React from "react";
import { colorPrimaryDark } from "../rsc/colors";
import { ReactComponent as PempemLogo } from "../rsc/pempem_logo.svg";
import { ReactComponent as Truck } from "../rsc/truck.svg";
import { ButtonPriceActionGold } from "./ButtonPriceActionGold";

type Props = {
    style?: React.CSSProperties
    onClick: () => void
}

export function CardPriceAtFarmFetch({ style, onClick }: Props) {
    return (<div style={{ ...style, display: "flex", flexDirection: "column", background: "#fff", border: `solid 3px ${colorPrimaryDark}` }}>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "5px", borderRadius: "5px", backgroundColor: "#AFC5C5", margin: "10px", padding:"5px 0" }}>
            <p style={{ textTransform: "uppercase", color: "#075255", fontWeight: 600, fontSize: "12px" }}>{t('price_at_farm_by')}</p>
            <PempemLogo style={{ height: "24px", width: "80px" }} fill={colorPrimaryDark} />
        </div>

        <div style={{ display: "flex", alignItems: "center", margin: "0 10px 0 10px" }}>
            <Truck />
            <div style={{ display: "flex", flexDirection: "column", flex: "1", gap: 10, margin: "0 0 0 10px" }}>
                <p style={{ margin: 0, padding: 0, fontWeight: "600", fontSize: "15px" }}>{t('price_at_farm_process')}</p>
            </div>
        </div>
        <ButtonPriceActionGold onClick={() => onClick()} style={{ margin: "10px", maxHeight: "45px", maxWidth: "500px" }} text={t('get_price_at_location')} />
    </div>)
}