import { t } from "i18next";
import React, { useRef } from "react";
import { colorGreen, colorPrimary, colorPrimaryDark } from "../rsc/colors";
import { ReactComponent as Location } from "../rsc/location.svg";
import { ReactComponent as PlanteDroite } from "../rsc/plante_droite.svg";
import { ReactComponent as PlanteGauche } from "../rsc/plante_gauche.svg";

type Props = {
    style?: React.CSSProperties
    onClick: () => void
}

export function CardPriceAtFarm({ style, onClick }: Props) {
    const instructionRef = useRef<HTMLDivElement>(null)

    return (
        <div ref={instructionRef} style={{ ...style, position: "relative", display: "flex", flexDirection: "column", background: "#fff", alignItems: "center", gap: "10px", paddingBottom: "10px", overflow:"hidden" }}>
            <p className="text-big" style={{ color: colorPrimaryDark, fontWeight: 800, margin: "10px 0 0 0", padding: 0, textAlign: "center" }}>{t('price_with_transport')}</p>
            <div style={{ display: "flex", gap: "5px", justifyContent: "center", textAlign: "center" }}>
                <p className="text-extra-big" style={{ color: colorPrimaryDark, fontWeight: 800, margin: 0, padding: 0 }}>{t('sell_fruit')} <span className="text-extra-big" style={{ color: colorGreen, fontWeight: 800, margin: 0, padding: 0 }}>{t('at_farm')}</span></p>
            </div>
            <p className="text-medium-plus" style={{ color: "black", margin: 0, padding: 0, textAlign: "center", fontWeight: 600 }}>{t('pempem_farm_advantage')}</p>
            <div onClick={() => onClick()} className="gradient-background-white-blue"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    border: `solid 2px ${colorPrimary}`,
                    padding: "5px 30px",
                    borderRadius: "8px",
                    zIndex: 2,
                    gap: "10px", alignItems: "center", cursor: "pointer"
                }} >
                <Location fill={colorPrimary} />
                <p className="text-medium" style={{ textTransform: "uppercase", fontWeight: 700, color: colorPrimaryDark, textDecoration: "none" }}>{t('set_location_for_price')}</p>
            </div>
            <div style={{ position: "absolute", width: "100%", bottom: -40, left: 0, right: 0, display: "flex", justifyContent: "space-between" }}>
                <div>
                    <PlanteGauche style={{ width: "280px", marginLeft: "-210px" }} />
                </div>
                <div style={{ display: "flex", justifyContent: "end" }}>
                    <PlanteDroite style={{ width: "280px", marginRight: "-210px" }} />
                </div>
            </div>
        </div>)
}